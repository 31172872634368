.highcharts-container {
    height: 400px !important;
    /* width: 25rem !important; */
    background-color: #f7f9fb;
}

#price-history-chart-container div div.highcharts-container {
    height: 500px !important;
}



.highcharts-credits {
    display: none !important;
}