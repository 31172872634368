.modal-container {
  display: flex;
  flex-direction: column;
}

.grey-text {
  /* margin: 0 !important; */
  color: #717171 !important;
  margin-top: 1.25em !important;
  margin-bottom: 0 !important;
}

.input-container {
  display: flex;
  margin-top: 1em;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1em;
}
.ind-inputs {
  display: flex;
  flex-direction: column;
}
.ind-inputs input,
select {
  width: 15em !important;
}
