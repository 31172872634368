@keyframes pulseColor {

    0%,
    100% {
        background-color: #f0f7ff;
        /* Light blue */
    }

    50% {
        background-color: #d0e7ff;
        /* Slightly darker blue */
    }
}

.animate-pulse {
    animation: pulseColor 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}