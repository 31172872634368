.session-container{
    border-radius: 14px;
    border: 1.35px solid #e3eaeb;
    /* width: 100%; */
    height: auto;
    margin-bottom: 1em;
    margin-right: 1em;
    background-color: #fff;
}
.u-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 5em;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid transparent; 
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; 
}

.u-submit-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.csv-download-button {
    background-color: #000000; 
    padding: 6px;
    border-radius: 8px;
    transition: all 0.3s ease; 
    cursor: pointer;
    border: 1px solid white;
}

.csv-download-button:hover {
    background-color: #ffffff; 
    border: 1px solid black;
}

.csv-icon {
    transition: filter 0.3s ease;
}

.csv-download-button:hover .csv-icon {
    filter: brightness(0); 
}



.clickable {
    position: relative;
    transition: all 0.3s ease; 
}


.clickable:hover::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px; 
    height: 2px;
    background-color: currentColor; 
    transition: width 0.3s ease;
    width: 0;
}


.clickable:hover::after {
    width: 100%;
}

.dashboard-access-button {
    cursor: pointer;
    border-radius: 10px;
    /* border-radius: 10%; */
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0.4em;
    font-size: 14px;
    transition: all 0.3s ease; 
    width: 8em;
}

.dashboard-access-button:hover {
    border: 1px solid white;
    background-color: white; 
    color: black; 
    transform: scale(1.05); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.rdrDefinedRangesWrapper{
    width: 0!important;
}