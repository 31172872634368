body{
/*	background: #0025FF;*/
/*	color: #fff;*/
	font-family: 'Manrope';
	height: 100vh;
/*	background: #f8f9fa;*/
}

.login-logo{
	height: 5.2em;
}

.heading-2{
	font-weight: 900;
	font-size: 2.7em;
	letter-spacing: 1.2px;
}

.login-presents{
	letter-spacing: 2px;
	font-weight: 200;
}

.greeting{
	font-weight: 800;
	font-size: 2em;
	letter-spacing: 1.5px;
}

.login-img-section{
/*	background:linear-gradient(0deg, rgba(0, 37, 255, 0.45), rgba(0, 37, 255, 0.45)), url('../img/login-bg.png');*/
	background:linear-gradient(0deg, rgba(0, 37, 255, 0.45), rgba(0, 37, 255, 0.45));
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.login-bottom-section{
	font-size: 0.75em;
}


.custom-input__input {
	height: 1.6rem;
	width: 100%;
	appearance: none;
	letter-spacing: 1.2px;
	border: 0;
	border-bottom: 0;
	outline: 0;
	font-size: 0.8rem;
	font-weight: 300;
	background-color: transparent;
	color: #333;
	transform: border-color 0.2s ease-in-out;
}
.custom-input__input:focus ~ .custom-input__border::after {
	transform: scaleX(1);
}
.custom-input .custom-input__border {
	position: relative;
}
.custom-input .custom-input__border::before, .custom-input .custom-input__border::after {
	content: "";
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	height: 2px;
	background: #eee;
	transition: background 0.2s ease-in-out;
}
.custom-input .custom-input__border::after {
	background: #0025ff;
	transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
	transform: scaleX(0);
}
.custom-input.custom-input--invalid.custom-input--dirty .custom-input__border::before, .custom-input.custom-input--invalid.custom-input--dirty .custom-input__border::after {
	background: red;
}

.login-card__label {
	display: block;
	margin: 1rem 0;
	position: relative;
}
.login-card__icon {
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0.33;
	color: #000;
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;
}
.login-card__text {
	display: block;
	font-size: 0.6em;
}
.login-card__input {
	display: block;
	height: 1.7rem;
	padding-right: 1rem;
	padding-bottom: 5px;
	box-sizing: border-box;
}
.login-card__input:focus + .login-card__icon {
	opacity: 0.5;
}

.otp-input input{
	height: 50px;
	width: 40px !important;
	margin: 0.7em;
	border-radius: 12px;
	padding: 1%;
	border: 1.4px solid #bbb;
}