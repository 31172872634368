*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope" , sans-serif;
}

html {
  scroll-behavior: smooth;
}
body{
  background-color: #f6f8fa;
}
#root{
  height: 100vh !important
}
.story-map {
   overflow: hidden;
}


a{
  text-decoration: none;
}

.pointer{
  cursor: pointer !important;
}

.table-header{
  font-weight: bold;
}

.text-orange{
  color: #FF8B00 !important;
}

.bordered{
  border-radius: 18px;
  border: 1.35px solid #eaeaea;
}

.w-05{
  width: 5% !important;
}
.w-10{
  width: 10% !important
}
.w-15{
  width: 15% !important
}
.w-20{
  width: 20% !important
}
.w-30{
  width: 30% !important
}
.w-40{
  width: 40% !important
}
.w-45{
  width: 45% !important
}
.w-60{
  width: 60% !important
}
.w-70{
  width: 70% !important
}
.w-80{
  width: 80% !important
}
.w-90{
  width: 90% !important
}
.w-95{
  width: 95% !important
}
.w-120{
  width: 120% !important;
}
.w-150{
  width: 150% !important;
}
.w-180{
  width: 180% !important;
}
.w-200{
  width: 200% !important;
}


.h-10{
  height: 10% !important;
}
.h-20{
  height: 20% !important;
}
.h-30{
  height: 30% !important;
}
.h-40{
  height: 40% !important;
}
.h-50{
  height: 50% !important;
}
.h-60{
  height: 60% !important;
}
.h-70{
  height: 70% !important;
}
.h-80{
  height: 80% !important;
}
.h-90{
  height: 90% !important;
}
.h-100{
  height: 100% !important;
}

.shadow-1{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.shadow-2{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.shadow-3{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.shadow-4{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.corner{
  border-radius: 12px
}
.corner-2{
  border-radius: 18px
}
.corner-3{
  border-radius: 24px
}


.white-card{
  background-color: #fff;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.bg-transparent{
  background-color: transparent !important;
}
.bg-black{
  background-color: #000 !important;
}
.bg-grey{
  background-color: #f8f9f9 !important;
}
.bg-grey-2{
  background-color: #b5b5c3 !important;
}
.bg-grey-3{
  background-color: #f8f9fa !important;
}
.bg-grey-4{
  background-color: #e0e7ea !important;
}
.bg-grey-5{
  background-color: #f6f8fa !important;
}
.bg-grey-6{
  background-color: #e2eaec !important;
}

.bg-light-yellow{
  background-color: #fff3cd;
  color: #664d03;
  opacity: 0.7
}
.bg-tan{
  background-color: #f3ebb8;
  color: #4c2c17;
}

.login-bg{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text-black{
  color: #222 !important;
}
.text-white{
  color: #fff !important;
}
.text-yellow{
  color: #fdd835 !important;
}

.thin{
  font-weight: 300 !important;
}
.bold{
  font-weight: 800 !important;
}
.bold-2{
  font-weight: 600 !important;
}
.light{
  font-weight: 300
}

.css-t89xny-MuiDataGrid-columnHeaderTitle{
  font-weight: 800 !important
}
.MuiTablePagination-selectLabel{
  margin-top: 1em;
}
.MuiTablePagination-displayedRows{
  margin-top: 1em;
}


.light-blue-bg{
  background-color: rgba(192, 222, 229, 0.2) !important;
}
.light-blue-bg-2{
  background-color: #6ecacf !important;
}


.bg-blue{
  background-color: #a5e3e3;
}
.bg-dark-blue{
  background-color: #29335c;
  color: #fff !important;
}
.bg-dark-blue-2{
  background-color: #2C00FF;
  color: #fff !important;
}

.bg-orange{
  background-color: #FF8B00 !important;
}
.bg-orange-pale{
  background-color: rgba(255, 139, 0, 0.4);
}
.bg-teal{
  background-color: #81cdc6;
}


.dashboard-card{
  border-radius: 12px; 
  color: white !important;
  cursor: pointer;
}
.dashboard-card-arrow{
  padding: 1%;
  color: #fff;
  border-radius: 200px;
  height: 40px;
  width: 40px;
}

.dashboard-card-text{
  font-weight: 400;
  font-size: 0.7em;
  font-size: 0.8em;
  letter-spacing: 0.5px
}

.dashboard-number{
  font-weight: 800;
  font-size: 1.8em;
  margin-top: -15px;
}

.dashboard-card-icon{
  background-color: #111;
  border-radius: 200px;
  height: 40px;
  width: 40px;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.border-right{
  border-right: 1.35px solid #eee
}

.dashboard-card-title{
  font-weight: 800
}

.dialog-title{
  font-weight: 600 !important
}

.orange-line{
  content: '';
  height: 3.5px;
  width: 7em;
  background-color: #ff8b00;
}


.text-small{
  font-size: 0.8em;
}

.center{
  margin-left: auto !important;
  margin-right: auto !important;
}

.label-2{
  color: #ccc;
  font-size: 0.85em;
  font-weight: 400;
}

.form-check-label{
  color: #333;
  font-weight: 500;
}

.btn-orange{
  border: none !important;
  border-radius: 200px !important;
  background-color: #ff8b00 !important;
  color: #fff !important;
  font-weight: 800;
  font-size: 1em;
  width: 10em;
  padding: 0.7%;
  transition: all 0.1s ease-in !important;
}

.btn-orange-non-radius{
  border: none !important;
  /* border-radius: 200px !important; */
  background-color: #ff8b00 !important;
  color: #fff !important;
  font-weight: 800;
  font-size: 1em;
  width: 10em;
  padding: 0.7%;
  transition: all 0.1s ease-in !important;
}

.btn-orange:hover{
  background-color: #e67d00;
}

.btn-orange-outlined{
  border: 1px solid #ff8b00 !important;
  border-radius: 200px !important;
  background-color: #fff !important;
  color: #ff8b00 !important;
  font-weight: 800 !important;
  font-size: 1em !important;
  /* width: 10em !important; */
  padding: 0.7% !important;
  transition: all 0.1s ease-in !important;
}

.no-border{
  border-collapse: collapse;
}

.home-section{
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
  background-color: #f6f8fa;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}



/*Switch*/
.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
  }

  .checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  .checkbox-wrapper-2 .ikxBAC,
  .checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
  }

  .checkbox-wrapper-2 .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
  }

  .checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
  }

  .checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
  }

  .checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #6e79d6;
  }

  .checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
  }

  .checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
  }

  .checkbox-wrapper-2 .ikxBAC:checked:hover {
    background-color: #535db3;
  }


.dt-buttons button {
    background: #fff; 
    color: #ff8b00;
    border: 1.2px solid #ff8b00;
    border-radius: 200px;
    padding: 5px 10px;
    margin-right: 5px;
}
.dt-buttons button:hover {
    background: #ff8b00 !important;
    color: #fff;
    border: 1.2px solid #ff8b00 !important;
}

.email{
  font-size: 0.6em;
}


.warning-pill{
  background-color: #fff3cd;
  color: #664d03;
  border-radius: 200px;
  /* border: 1.2px solid #ffecb5; */
  text-align: center;
  padding: 0.5%;
  font-size: 0.7em;
  font-weight: bold;
  max-width: 10em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-pill{
  background-color: #d1e7dd;
  color: #0f5132;
  border-radius: 200px;
  border: 1.2px solid #badbcc;
  text-align: center;
  padding: 0.5%;
  font-size: 0.7em;
  font-weight: bold;
  max-width: 10em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger-pill{
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 200px;
  border: 1.2px solid #f6cacf;
  text-align: center;
  padding: 0.5%;
  font-size: 0.7em;
  font-weight: bold;
  max-width: 10em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grey-pill{
  background-color: #ededed;
  border-radius: 6px;
  width: 7em;
  text-align: center;
  padding: 0.7%
}

.accordion-item{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  border-radius: 8px !important;
}

.accordion-item::before{
  background-color: none !important;
  position: static !important
}


.black-2{
  color: #444;
}

.grey{
  color: #aaa !important
}
.grey-2{
  color: #b5b5c3 !important;
}

.ml-1{
  margin-left: 0.3em
}
.ml-2{
  margin-left: 0.5em
}
.ml-3{
  margin-left: 2rem
}
.mr-2{
  margin-right: 0.5em
}
.mr-3{
  margin-right: 2rem
}

.large{
  font-size: 1.25em
}
.large-2{
  font-size: 1.5em
}
.small{
  font-size: 0.8em
}
.small-2{
  font-size: 0.7em
}
.medium{
  font-size: 0.9em
}
.medium-2{
  font-size: 1.2em
}
.medium-3{
  font-size: 1.3em !important
}
.medium-4{
  font-size: 1.05em !important
}
.large{
  font-size: 1.5em;
}
.large-2{
  font-size: 1.8em;
}
.large-3{
  font-size: 2em;
}

small{
  font-size: 0.75em;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #29335c;
}


  .checkbox-wrapper-16 *,
  .checkbox-wrapper-16 *:after,
  .checkbox-wrapper-16 *:before {
    box-sizing: border-box;
  }

  .checkbox-wrapper-16 .checkbox-input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
    border-color: #111;
    
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
    background-color: #111;
    border-color: #111;
  }

  .checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
  }

  .checkbox-wrapper-16 .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    min-height: 5rem;
    border-radius: 0.5rem;
    border: 1.5px solid #ddd;
    background-color: #fff;
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
  }
  .checkbox-wrapper-16 .checkbox-tile:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .checkbox-wrapper-16 .checkbox-tile:hover {
    border-color: #666;
  }
  .checkbox-wrapper-16 .checkbox-tile:hover:before {
    transform: scale(1);
    opacity: 1;
  }

  .checkbox-wrapper-16 .checkbox-icon {
    transition: 0.375s ease;
  }
  .checkbox-wrapper-16 .checkbox-icon svg {
    width: 3rem;
    height: 3rem;
  }

  .checkbox-wrapper-16 .checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
  }

.pill-blue{
  font-size: 0.8em;
  background-color: #e1e1e7;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  min-width: 6em;
  width: auto;
  padding: 0.7%;
  margin-top: 0.5em
}

.border{
  border-radius: 12px;
  border: 1.5px solid #e1e1e7;
}
.border-orange{
  border: 1.2px solid rgb(255, 139, 0) !important;
}

.outline-card{
  border-radius: 14px;
  border: 1.35px solid #e3eaeb;
  width: 100%;
  height: auto;
  margin-bottom: 1em;
  margin-right: 1em;
  background-color: #fff;
/*  transition: height 0.15s ease-in-out;*/
}
.outline-card-2{
  border-radius: 18px;
  border: 1.35px solid #e3eaeb;
  height: auto;
  background-color: transparent;
/*  transition: height 0.15s ease-in-out;*/
}

.btn-outline{
  border-radius: 8px;
  border: 1.35px solid #e3eaeb;
  width: auto;
  margin-bottom: 1em;
  margin-right: 1em;
  background-color: transparent
}

.outline-card-icon{
  border-radius: 4px;
  height: 25px;
  width: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  background-color: #FF8B00;
  color: #fff;
}

.outline-icon{
  height: 45px;
}

.priority{
  background-color: #FF8B00;
  color: #fff;
  font-weight: bold;
  height: 30px;
  width: 30px;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.summary-card{
  background-color: #f8f8fb;
  border-radius: 14px;
/*  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
}
.summary-card-icon{
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: #ff8b00;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.outline-list-item{
  border-radius: 18px;
  border: 1.5px solid #eee;
  box-shadow: 'none' !important
}

.outline-list-item::before{
  position: static !important
}


.flag-card-icon{
  color: #85b9ac;
  background-color: #ebf4f2;
  border-radius: 18px;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag{
  background-color: #eff3f8;
  border-radius: 8px;
  color: #363d59;
  height: 6em
}

.grey-area{
  border-radius: 12px;
  background-color: #eef1f2;
  overflow-y: scroll;
  height: 600px;
  max-height: 600px;
}

.hotel-listing-card{
  background-position: center;
  background-size: cover;
  height: 120px;
  border-radius: 14px;
  cursor: pointer;
  margin: 0.8em;
  transition: all 0.1s ease-in;
}
.hotel-listing-card-gradient{
  height: 120px;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 50%); 
}

.hotel-listing-card-2{
  max-width: 13em;
  background-position: center;
  background-size: cover;
  height: 150px;
  border-radius: 14px;
  cursor: pointer;
  margin: 0.8em;
  transition: all 0.1s ease-in;
}
.hotel-listing-card-gradient-3{
  height: 150px;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 50%); 
}

.hotel-listing-card-3{
  max-width: 13em;
  background-position: center;
  background-size: cover;
  height: 100px;
  border-radius: 14px;
  cursor: pointer;
  margin: 0.8em;
  transition: all 0.1s ease-in;
}
.hotel-listing-card-gradient-3{
  height: 100px;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 90%); 
}

.hotel-listing-card-4{
  width: auto;
  background-position: 0 40%;
  background-size: cover;
  height: 120px;
  border-radius: 14px;
  margin: 0.8em;
  transition: all 0.1s ease-in;
}
.hotel-listing-card-gradient-4{
  height: 200px;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 90%); 
}

.hotel-listing-card:hover{
  transform: scale(1.04)
}

.view-more{
  border-radius: 200px;
  padding: 0.5%;
  font-size: 0.7em;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in
}

.view-more:hover{
  background-color: #cad0d3;
  cursor: pointer
}

.line{
  content: '';
  height: 1.3px;
  background-color: #e0e7ea;
  width: 100% !important;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.mapboxgl-ctrl-logo{
  display: none;
  visibility: hidden;
}

.mapboxgl-ctrl-attrib-inner{
 display: none;
  visibility: hidden; 
}


/*.css-tlfecz-indicatorContainer{
  display: none !important;
}*/

.search-btn{
  background-color: transparent;
  border-radius: 200px;
  height: 40px;
  width: 40px;
  border: 1.2px solid #aeaeae;
  font-size: 1.2em;
  padding: 0.5%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9a9a9a
}

.padlock{
  height: 200px;
}

.ota-logo-sm{
  height: 15px;
}
.chart-btn{
  height: 24px;
  width: 24px;
  border-radius: 200px;
  background-color: #FF8B00;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2%;
  font-size: 0.8em;
}
.chart-btn-2{
  height: 18px;
  width: 18px;
  border-radius: 200px;
  background-color: #29335c;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2%;
  font-size: 0.8em;
}
.orange-btn{
  width: auto;
  max-width: 18em;
  border-radius: 200px;
  background-color: #FF8B00;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 0.8em;
}
.orange-circle{
  height: 30px;
  width: 30px;
  border-radius: 200px;
  background-color: #FF8B00;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 0.8em;
}


.height-auto{
  height: auto !important;
}
.height-100{
  height: 100px !important;
}
.height-200{
  height: 200px !important;
}
.height-300{
  height: 300px !important;
}
.height-400{
  height: 400px !important;
}
.height-500{
  height: 500px !important;
}


.max-height-200{
  max-height: 200px !important;
}
.max-height-300{
  max-height: 300px !important;
}
.max-height-400{
  max-height: 400px !important;
}
.max-height-600{
  max-height: 600px !important;
}
.max-height-800{
  max-height: 800px !important;
}

.square-img-1{
  height: 75px;
  width: 75px;
}

.border-hover:hover{
  border-width: 1.5px;
  border-color: #999;
}

.react-multiple-carousel__arrow--left{
  display: none;
}

.MuiBox-root{
  height: auto !important;
}


.span-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


.fw-bold{
  font-weight: bold;
}

.f-black{
  color: black;
}

.collapse-btn{
  border: 1.35px solid #bcbcbc;
  border-radius: 200px;
  padding: 0.3em;
  font-size: 1.4em;
  color: #565656;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.custom-input{
  padding: 0.2em;
  border: 1.5px solid #cdcdcd;
  border-radius: 4px;
  background-color: #fff;
}

.Toastify__toast-container {
  z-index: 9000;
}

.fixed-width-pill {
  width: 275px; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fixed-width-pill span:last-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1; 
  width: 100%;
}
.line-chart-usage{
  height: 100% !important;
  width: 100% !important;
}

.text-justify{
  text-align: justify !important;
}

.blink {
  animation: blink 1s infinite;
}