/*Admin CSS*/

.dashboard-heading{
	font-weight: 900;
}

.admin-card{
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 12px;
	font-size: 0.75em;
	background-color: #fff;
}

.admin-card-section{
	background-color: #f8f9fa;
	border-radius: 12px;
}

.admin-card-header{
	font-weight: 700;
	font-size: 1.5em;
}

.admin-card-button{
	padding: 0.5%;
	color: #fff;
	background: #ff8b00;
	border: none;
	border-radius: 200px;
	width: 12em;
	font-size: 1em;
	font-weight: 700;
	transition: all 0.1s ease-in
}
.admin-card-button:hover{
	background-color: #e67d00;
}

.admin-card-time{
	font-size: 0.9em;
	color: #999;	
	line-height: 0.1;
}

.admin-card-date{
	font-size: 0.9em;
	color: #222;
	font-weight: bold;	
}


label{
	font-size: 0.8em;
	font-weight: bold
}

.hotel{
	height: 18em
}