#past-checkin-roomtype,#past-thirty-days-roomtype{
    padding: 0.5em;
}
.home-card {
  padding: 1em;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#revenueRR{
    height: 19em;
}
.single-date{
    color: white;
    background-color:black;
    padding: 3px;
    border-radius: 20px;
    width: fit-content;
}

/* Add border-bottom only to <td> in the last row */

  
tbody tr:not(:last-child) {
  border-width: 0 !important;
}
tbody tr:last-child {
  border-top: 0; 
}

tbody tr td{
  padding:6px 15px !important;
}

@keyframes blink {
  0% {
 /* Initial color */
 border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Soft green shadow */
  }
  50% {
/* Brighter green for mid-animation */
border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 255, 0, 0.7); /* Stronger green shadow */
  }
  100% {
    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0, 255, 0, 1); /* Intense green shadow */
  }
}


.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #777;
  background-color: #f8f8f8;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
}

.no-data-container::before {
  content: '📉'; /* Optional: Adding an icon */
  font-size: 2rem;
  margin-right: 10px;
}


@media (max-width: 500px) {
  .home-card {
    font-size: 0.7em; /* Reduce font size when screen width is below 400px */
  }
}



  /* table { */
    /* border-collapse: collapse; */
    /* border: none; */
  /* } */